import { useState } from "react"
import { UseFormReturn } from "react-hook-form"
import { gql } from "~/__generated__"
import {
  CampaignDeliverableDetailFragmentFragment,
  CampaignDeliverableStatus,
  CampaignDeliverableType,
} from "~/__generated__/graphql"
import { useViewer } from "~/auth/viewer-context"
import { Brief } from "~/campaign-deliverables/brief"
import { Deliverables } from "~/campaign-deliverables/deliverables"
import { ProductBrief } from "~/campaign-deliverables/product-brief"
import { RejectModal } from "~/campaign-deliverables/reject-modal"
import { ScaffoldAdButton } from "~/campaign-deliverables/schedule-button"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { useValidationErrors } from "~/common/use-validation-errors"
import { Button } from "~/ui/button"
import { Form } from "~/ui/form"
import { toast } from "~/ui/use-toast"
import { CampaignDeliverableFormSchema } from "./campaign-deliverable-detail-screen"

gql(`
  fragment CampaignDeliverableDueDates on CampaignDeliverable {
    nextDueDate
    deliverableBriefDueDate
    deliverableBriefDueDateCanonical
    assignedToCreativeDueDate
    awaitingCreatorApprovalDueDate
    awaitingAccountManagerDueDate
    awaitingClientApprovalDueDate
    approvedDueDate
    adScaffoldedDueDate
    inProductionDueDate
  }
`)

const briefMutation = gql(/* GraphQL */ `
  mutation updateDeliverableBriefs(
    $input: CampaignDeliverableBriefUpdateInput!
  ) {
    campaignDeliveryBriefUpdate(input: $input) {
      campaignDeliverable {
        id
        productBriefId
        trackingUrls
        keyDirection
        status
        ...CampaignDeliverableDueDates
        canUpdateBrief {
          value
        }
        canApproveOrReject {
          value
        }
        canUpdateDeliverables {
          value
        }
        statusLogs(first: 100) {
          edges {
            node {
              ...StatusLog
            }
          }
        }
      }
    }
  }
`)

const deliverablesMutation = gql(/* GraphQL */ `
  mutation updateDeliverables(
    $input: CampaignDeliverableDeliverablesUpdateInput!
  ) {
    campaignDeliveryDeliverablesUpdate(input: $input) {
      campaignDeliverable {
        id
        productBriefId
        status
        ctaButton
        ctaUrl
        deliverableContent
        rejectionFeedback
        ...CampaignDeliverableDueDates
        canUpdateDeliverables {
          value
        }
        canApproveOrReject {
          value
        }
        statusLogs(first: 100) {
          edges {
            node {
              ...StatusLog
            }
          }
        }
      }
    }
  }
`)

const acceptMutation = gql(/* GraphQL */ `
  mutation acceptDeliverables($input: CampaignDeliverableAcceptUpdateInput!) {
    campaignDeliverableAcceptUpdate(input: $input) {
      campaignDeliverable {
        id
        productBriefId
        status
        ctaButton
        ctaUrl
        deliverableContent
        rejectionFeedback
        keyDirection
        ...CampaignDeliverableDueDates
        canUpdateDeliverables {
          value
        }
        canApproveOrReject {
          value
        }

        creatorBrand {
          id
          creators(first: 20) {
            edges {
              node {
                id
              }
            }
          }
        }
        statusLogs(first: 100) {
          edges {
            node {
              ...StatusLog
            }
          }
        }
      }
    }
  }
`)

type CampaignDeliverableBriefsProps = {
  campaignDeliverable: CampaignDeliverableDetailFragmentFragment
  deliverableForm: UseFormReturn<CampaignDeliverableFormSchema>
}

export const CampaignDeliverableBriefs = ({
  campaignDeliverable,
  deliverableForm,
}: CampaignDeliverableBriefsProps) => {
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false)
  const { viewer } = useViewer()

  const [execBriefMutation, briefMutationResult] =
    useSafeMutation(briefMutation)
  const [execDeliverablesMutation, deliverablesMutationResult] =
    useSafeMutation(deliverablesMutation)
  const [execAcceptMutation, acceptMutationResult] =
    useSafeMutation(acceptMutation)
  useValidationErrors(deliverableForm.setError, briefMutationResult)
  useValidationErrors(deliverableForm.setError, deliverablesMutationResult)
  useValidationErrors(deliverableForm.setError, acceptMutationResult)

  const briefSubmit = async ({
    values,
    saveProgress,
  }: {
    values: CampaignDeliverableFormSchema
    saveProgress: boolean
  }) => {
    const trackingUrls = values.trackingUrls.filter((url) => url.trim() !== "")

    const result = await execBriefMutation({
      variables: {
        input: {
          id: campaignDeliverable.id,
          saveProgress,
          deliverableInput: {
            productBriefId: values.productBriefId,
            trackingUrls,
            keyDirection: values.keyDirection,
          },
        },
      },
    })

    if (!result.errors) {
      if (saveProgress) {
        toast({
          title: "Briefs progress saved",
          description: "The progress have been saved",
        })
      } else {
        const inProductionDeliverableTypes = [
          CampaignDeliverableType.PodcastGuest,
          CampaignDeliverableType.LiveOrVirtualEventSpeakingFee,
          CampaignDeliverableType.CustomLiveEvent,
          CampaignDeliverableType.CustomVirtualEvent,
          CampaignDeliverableType.CustomPodcastContent,
        ]

        if (
          inProductionDeliverableTypes.includes(
            campaignDeliverable.deliverableType
          )
        ) {
          toast({
            title: "Brief submitted",
            description:
              "The deliverable status has been updated to In Production",
          })
        } else {
          toast({
            title: "Brief submitted",
            description: "The Brief has been assigned to the Creative",
          })
        }
      }
    }
  }

  const deliverablesSubmit = async ({
    values,
    saveProgress,
  }: {
    values: CampaignDeliverableFormSchema
    saveProgress: boolean
  }) => {
    const result = await execDeliverablesMutation({
      variables: {
        input: {
          id: campaignDeliverable.id,
          saveProgress,
          deliverableInput: {
            ctaButton: values.ctaButton,
            ctaUrl: values.ctaUrl,
            deliverableContent: values.deliverableContent,
          },
        },
      },
      refetchQueries: ["CampaignDeliverableDetail"],
    })

    if (!result.errors) {
      if (saveProgress) {
        toast({
          title: "Deliverables progress saved",
          description: "The progress have been saved",
        })
      } else {
        if (
          campaignDeliverable.status ===
            CampaignDeliverableStatus.AssignedToCreative &&
          campaignDeliverable.creatorBrand.creators.edges.find(
            (e) => e.node.id === viewer.id
          )
        ) {
          toast({
            title: "Deliverables submitted",
            description: "Sent to Account Manager for approval",
          })
        } else if (
          campaignDeliverable.status ===
          CampaignDeliverableStatus.AssignedToCreative
        ) {
          if (
            campaignDeliverable.creatorBrand.creatives.edges.filter(
              (c) => c.node.id === viewer.id
            ).length > 0
          ) {
            toast({
              title: "Deliverables submitted",
              description: "Sent to Account Manager for approval",
            })
          } else {
            toast({
              title: "Deliverables submitted",
              description: "Sent to Creator for approval",
            })
          }
        } else {
          toast({
            title: "Deliverables submitted",
            description: "The deliverables have been submitted",
          })
        }
      }
    }
  }

  const onSubmit = async ({
    values,
    saveProgress = false,
  }: {
    values: CampaignDeliverableFormSchema
    saveProgress?: boolean
  }) => {
    switch (campaignDeliverable.status) {
      case CampaignDeliverableStatus.BriefNeeded:
        briefSubmit({ values, saveProgress })
        break
      case CampaignDeliverableStatus.AssignedToCreative:
        deliverablesSubmit({ values, saveProgress })
        break
      default:
        throw new Error(`Unexpected status: ${campaignDeliverable.status}`)
    }
  }

  const onSaveProgress = async (values: CampaignDeliverableFormSchema) => {
    switch (campaignDeliverable.status) {
      case CampaignDeliverableStatus.BriefNeeded:
        briefSubmit({ values, saveProgress: true })
        break
      case CampaignDeliverableStatus.AssignedToCreative:
        deliverablesSubmit({ values, saveProgress: true })
        break
      default:
        throw new Error(`Unexpected status: ${campaignDeliverable.status}`)
    }
  }

  const onAccept = async ({
    values,
    approved,
    saveProgress,
  }: {
    values: CampaignDeliverableFormSchema
    approved: boolean
    saveProgress?: boolean
  }) => {
    const trackingUrls = values.trackingUrls.filter((url) => url.trim() !== "")

    /**
      If deliverable status is:

      Awaiting Account Manager Approval
      - If approved, set status to Awaiting Client Approval
      - If not approved, set status to Assigned to Creative

      Awaiting Creator Approval
      - If approved, set status to Awaiting Account Manager Approval
      - If not approved, set status to Assigned to Creative

      Awaiting Client Approval
      - If approved, set status to Approved
      - If not approved, set status to Awaiting Account Manager Approval
    **/
    const getStatus = (approved: boolean) => {
      switch (campaignDeliverable.status) {
        case CampaignDeliverableStatus.AwaitingAccountManagerApproval:
          if (approved) {
            return CampaignDeliverableStatus.AwaitingClientApproval
          } else {
            return CampaignDeliverableStatus.AssignedToCreative
          }
        case CampaignDeliverableStatus.AwaitingCreatorApproval:
          if (approved) {
            return CampaignDeliverableStatus.AwaitingAccountManagerApproval
          } else {
            return CampaignDeliverableStatus.AssignedToCreative
          }
        case CampaignDeliverableStatus.AwaitingClientApproval:
          if (approved) {
            return CampaignDeliverableStatus.Approved
          } else {
            return CampaignDeliverableStatus.AwaitingAccountManagerApproval
          }
        default:
          throw new Error(`Unexpected status: ${campaignDeliverable.status}`)
      }
    }

    const result = await execAcceptMutation({
      variables: {
        input: {
          id: campaignDeliverable.id,
          saveProgress,
          approved,
          deliverableInput: {
            status: getStatus(approved),
            trackingUrls,
            keyDirection: values.keyDirection,
            ctaButton: values.ctaButton,
            ctaUrl: values.ctaUrl,
            deliverableContent: values.deliverableContent,
            rejectionFeedback: values.rejectionFeedback,
          },
        },
      },
    })

    const getToastMessage = (approved: boolean) => {
      switch (campaignDeliverable.status) {
        case CampaignDeliverableStatus.AwaitingAccountManagerApproval:
          if (approved) {
            return "Sent to client for approval"
          } else {
            return "Sent back to creative for review"
          }
        case CampaignDeliverableStatus.AwaitingCreatorApproval:
          if (approved) {
            return "Sent to account manager for approval"
          } else {
            return "Assigned to creative"
          }
        case CampaignDeliverableStatus.AwaitingClientApproval:
          if (approved) {
            return "Approved"
          } else {
            return "Sent back to account manager for review"
          }
        default:
          throw new Error(`Unexpected status: ${campaignDeliverable.status}`)
      }
    }

    if (!saveProgress && !result.errors) {
      if (approved) {
        toast({
          title: "Deliverables approved",
          description: getToastMessage(approved),
        })
      } else {
        toast({
          title: "Deliverables rejected",
          description: getToastMessage(approved),
        })
      }
    }
  }

  const showSaveProgressAndSubmitButton =
    (campaignDeliverable.status ===
      CampaignDeliverableStatus.AssignedToCreative &&
      campaignDeliverable.canUpdateDeliverables.value) ||
    (campaignDeliverable.status === CampaignDeliverableStatus.BriefNeeded &&
      campaignDeliverable.canUpdateBrief.value)

  const showApproveProvideFeedbackButtons =
    (campaignDeliverable.status ===
      CampaignDeliverableStatus.AwaitingAccountManagerApproval &&
      campaignDeliverable.canApproveOrReject.value) ||
    (campaignDeliverable.status ===
      CampaignDeliverableStatus.AwaitingCreatorApproval &&
      campaignDeliverable.canApproveOrReject.value) ||
    (campaignDeliverable.status ===
      CampaignDeliverableStatus.AwaitingClientApproval &&
      campaignDeliverable.canApproveOrReject.value)

  const showScaffoldAdButton =
    campaignDeliverable.canSchedule.value &&
    campaignDeliverable.status === CampaignDeliverableStatus.Approved &&
    (campaignDeliverable.placement === "Primary" ||
      campaignDeliverable.placement === "Secondary" ||
      campaignDeliverable.placement === "Intro")

  const formProcessing =
    briefMutationResult.loading || deliverablesMutationResult.loading

  const inProductionDeliverableTypes = [
    CampaignDeliverableType.PodcastGuest,
    CampaignDeliverableType.LiveOrVirtualEventSpeakingFee,
    CampaignDeliverableType.CustomLiveEvent,
    CampaignDeliverableType.CustomVirtualEvent,
    CampaignDeliverableType.CustomPodcastContent,
  ]

  const isProductionDeliverable = inProductionDeliverableTypes.includes(
    campaignDeliverable.deliverableType
  )

  return (
    <Form {...deliverableForm}>
      {!deliverableForm.formState.isValid && (
        <div className="bg-foreground text-background text-center py-5 px-5 rounded font-normal text-sm text-white mb-4">
          Please correct the required fields below to save your deliverable
          updates.
        </div>
      )}

      <form
        onSubmit={deliverableForm.handleSubmit((values) =>
          onSubmit({ values: values, saveProgress: false })
        )}
        className="flex flex-col gap-4"
      >
        <ProductBrief
          form={deliverableForm}
          campaignDeliverable={campaignDeliverable}
        />
        <Brief
          form={deliverableForm}
          campaignDeliverable={campaignDeliverable}
        />
        {campaignDeliverable.status !== CampaignDeliverableStatus.BriefNeeded &&
          !isProductionDeliverable && (
            <Deliverables
              form={deliverableForm}
              campaignDeliverable={campaignDeliverable}
            />
          )}

        {showSaveProgressAndSubmitButton && (
          <div className="border-t border-gray-d0 pt-10 space-x-2 mt-4">
            <Button
              type="submit"
              disabled={formProcessing}
              onClick={deliverableForm.handleSubmit(onSaveProgress)}
            >
              Save Progress
            </Button>
            <Button
              type="submit"
              disabled={formProcessing}
              onClick={deliverableForm.handleSubmit((values) =>
                onSubmit({ values })
              )}
            >
              Submit
            </Button>
          </div>
        )}

        {showApproveProvideFeedbackButtons && (
          <div className="border-t border-gray-d0 pt-10 space-x-2 mt-4">
            <Button
              type="submit"
              disabled={formProcessing}
              onClick={deliverableForm.handleSubmit((values) =>
                onAccept({ values, approved: true })
              )}
            >
              Approve
            </Button>
            <Button
              type="submit"
              disabled={formProcessing}
              onClick={() => {
                onAccept({
                  values: deliverableForm.getValues(),
                  approved: false,
                  saveProgress: true,
                })
                setIsRejectModalOpen(true)
              }}
            >
              Provide Feedback
            </Button>

            <RejectModal
              isOpen={isRejectModalOpen}
              onClose={() => setIsRejectModalOpen(false)}
              onReject={(message: string) => {
                deliverableForm.setValue("rejectionFeedback", message)
                setIsRejectModalOpen(false)
                onAccept({
                  values: deliverableForm.getValues(),
                  approved: false,
                })
              }}
            />
          </div>
        )}
        {showScaffoldAdButton && (
          <div className="border-t border-gray-d0 pt-10 space-x-2 mt-4">
            <ScaffoldAdButton campaignDeliverable={campaignDeliverable} />
          </div>
        )}
      </form>
    </Form>
  )
}

import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  addMonths,
  addWeeks,
  eachDayOfInterval,
  endOfISOWeek,
  endOfMonth,
  format,
  formatISO,
  isBefore,
  startOfISOWeek,
  startOfMonth,
  subMonths,
  subWeeks,
} from "date-fns"

import { sortBy } from "lodash"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { z } from "zod"
import { gql } from "~/__generated__"
import {
  CampaignDeliverableRowFragment,
  CampaignDeliverableStatus,
  CampaignDeliverableType,
  CampaignStatus,
  Role,
} from "~/__generated__/graphql"
import { useViewer } from "~/auth/viewer-context"
import { CampaignDeliverableStatusLabel } from "~/campaigns/campaign-deliverable-status"
import { DeliverableStatus } from "~/campaigns/deliverable-status"
import { DeliverableType } from "~/campaigns/deliverable-type"
import { cn } from "~/common/cn"
import { formatDate } from "~/common/date-formatting"
import { campaignDeliverablePath } from "~/common/paths"
import { pickNonDefaults } from "~/common/zod-changed-defaults"
import { URLParamsSerializer } from "~/common/zod-search-params"
import { CompanyLogo } from "~/companies/company-logo"
import { FilterField } from "~/fields/filter-field"
import { SelectField } from "~/fields/select-field"
import arrowDown from "~/images/icons/arrow-down"
import arrowRight from "~/images/icons/arrow-right"
import CalendarIcon from "~/images/icons/calendar.svg?react"
import chevronDown from "~/images/icons/chevron-down"
import chevronUp from "~/images/icons/chevron-up"
import TableIcon from "~/images/icons/table.svg?react"
import { ActiveFilters } from "~/search/active-filters"
import { Button } from "~/ui/button"
import { GraphqlError } from "~/ui/errors"
import { Form } from "~/ui/form"
import { Heading } from "~/ui/heading"
import { Invariant } from "~/ui/invariant"
import { LoadingIndicatorCentered } from "~/ui/loading-indicator"
import { Table, TableCell, TableHead, TableHeader, TableRow } from "~/ui/table"
import Text from "~/ui/typography"
import { ActionItems } from "./action-items"

const query = gql(/* GraphQL */ `
  query DeliverablesDashboardQuery(
    $filter: CampaignDeliverablesFilterInput
    $after: String
  ) {
    creatorBrands(first: 250, filters: { statuses: [ACTIVE] }) {
      nodes {
        id
        name
      }
    }
    campaignDeliverables(first: 50, filter: $filter, after: $after) {
      pageInfo {
        ...Pagination
      }
      edges {
        node {
          id
          ...CampaignDeliverableRow
        }
      }
    }
  }
`)

enum Period {
  weeks_2 = "weeks_2",
  weeks_4 = "weeks_4",
  upcoming = "upcoming",
}

const periodLables: Record<Period, string> = {
  [Period.weeks_2]: "2 Weeks",
  [Period.weeks_4]: "4 Weeks",
  [Period.upcoming]: "Upcoming",
}

enum CalendarView {
  weekly = "weekly",
  monthly = "monthly",
}

const calendarViewLables: Record<CalendarView, string> = {
  [CalendarView.weekly]: "Weekly",
  [CalendarView.monthly]: "Monthly",
}

enum DashboardView {
  table = "table",
  calendar = "calendar",
}

const formSchema = z.object({
  period: z.nativeEnum(Period),
  calendarView: z.nativeEnum(CalendarView),
  dashboardView: z.nativeEnum(DashboardView),
  statuses: z.array(z.nativeEnum(CampaignDeliverableStatus)),
  creatorBrandIds: z.array(z.string()),
  deliverableTypes: z.array(z.nativeEnum(CampaignDeliverableType)),
  sortField: z.enum(["nextDueDate", "publishDate"]).optional(),
  sortDirection: z.enum(["asc", "desc"]).optional(),
})

const formDefaults: z.infer<typeof formSchema> = {
  period: Period.upcoming,
  calendarView: CalendarView.weekly,
  dashboardView: DashboardView.table,
  statuses: [],
  creatorBrandIds: [],
  deliverableTypes: [],
  sortField: "nextDueDate",
  sortDirection: "asc",
}
const serializer = new URLParamsSerializer(formSchema, formDefaults)

let now = new Date()
let startOfWeek = startOfISOWeek(now)

function maxDate(period: Period): Date | undefined {
  switch (period) {
    case Period.weeks_2:
      return endOfISOWeek(addWeeks(startOfWeek, 1))
    case Period.weeks_4:
      return endOfISOWeek(addWeeks(startOfWeek, 3))
    case Period.upcoming:
      return
  }
}

export const DeliverablesDashboardScreen: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { viewer } = useViewer()

  const [currentDate, setCurrentDate] = React.useState(new Date())

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: serializer.deserialize(searchParams),
  })

  const formData = form.watch()
  useEffect(() => {
    const newSearchParams = serializer.serialize(formData)
    if (newSearchParams.toString() !== searchParams.toString()) {
      setSearchParams(newSearchParams, { replace: true })
    }
  }, [formData, searchParams, setSearchParams])

  const nonDefaultValues = pickNonDefaults(formSchema, formDefaults, formData)

  const activeFiltersCount = Object.keys(nonDefaultValues).filter(
    (key) =>
      key !== "search" &&
      ((key === "statuses" && form.watch("statuses").length > 0) ||
        (key === "creatorBrandIds" &&
          form.watch("creatorBrandIds").length > 0) ||
        (key === "deliverableTypes" &&
          form.watch("deliverableTypes").length > 0))
  ).length

  const period = form.watch("period")
  const calendarView = form.watch("calendarView")
  const max = maxDate(period)

  const startDate =
    calendarView === CalendarView.weekly
      ? startOfISOWeek(currentDate)
      : startOfMonth(currentDate)

  const endDate =
    calendarView === CalendarView.weekly
      ? endOfISOWeek(currentDate)
      : endOfMonth(currentDate)

  const result = useQuery(query, {
    variables: {
      filter: {
        actionDate: {
          ...(formData.dashboardView === DashboardView.table &&
            max && { max: formatISO(max, { representation: "date" }) }),
        },
        publishDate: {
          ...(formData.dashboardView === DashboardView.calendar &&
            startDate && {
              min: formatISO(startDate, { representation: "date" }),
            }),
          ...(formData.dashboardView === DashboardView.calendar &&
            endDate && {
              max: formatISO(endDate, { representation: "date" }),
            }),
        },
        statuses:
          formData.statuses.length > 0
            ? formData.statuses
            : formData.dashboardView === DashboardView.calendar
            ? [
                CampaignDeliverableStatus.BriefNeeded,
                CampaignDeliverableStatus.AssignedToCreative,
                CampaignDeliverableStatus.AwaitingCreatorApproval,
                CampaignDeliverableStatus.AwaitingAccountManagerApproval,
                CampaignDeliverableStatus.AwaitingClientApproval,
                CampaignDeliverableStatus.InProduction,
                CampaignDeliverableStatus.Approved,
                CampaignDeliverableStatus.AdScaffolded,
                CampaignDeliverableStatus.Scheduled,
                CampaignDeliverableStatus.Published,
              ]
            : [
                CampaignDeliverableStatus.BriefNeeded,
                CampaignDeliverableStatus.AssignedToCreative,
                CampaignDeliverableStatus.AwaitingCreatorApproval,
                CampaignDeliverableStatus.AwaitingAccountManagerApproval,
                CampaignDeliverableStatus.AwaitingClientApproval,
                CampaignDeliverableStatus.InProduction,
                CampaignDeliverableStatus.Approved,
                CampaignDeliverableStatus.AdScaffolded,
                CampaignDeliverableStatus.Scheduled,
              ],

        creatorBrandIds:
          formData.creatorBrandIds.length > 0 ? formData.creatorBrandIds : [],

        deliverableTypes:
          formData.deliverableTypes.length > 0 ? formData.deliverableTypes : [],

        campaignStatus: CampaignStatus.Active,
      },
      after: null,
    },
  })

  const records = (
    result.data?.campaignDeliverables?.edges ??
    result.previousData?.campaignDeliverables?.edges ??
    []
  ).map((e) => e.node)

  const creatorBrands = result.data?.creatorBrands?.nodes ?? []

  const creatorBrandOptions = creatorBrands.map((cb) => ({
    label: cb.name,
    value: cb.id,
  }))

  // Sort selected brands to top
  creatorBrandOptions.sort((a, b) => {
    const aSelected = formData.creatorBrandIds.includes(a.value)
    const bSelected = formData.creatorBrandIds.includes(b.value)
    if (aSelected && !bSelected) return -1
    if (!aSelected && bSelected) return 1
    return 0
  })

  const isClient = viewer?.role === Role.Client

  const statusOptions = isClient
    ? [
        { label: "Brief Needed", value: CampaignDeliverableStatus.BriefNeeded },
        {
          label: "Ad Creation In Progress",
          value: CampaignDeliverableStatus.AdCreationInProgress,
        },
        {
          label: "Awaiting Client Approval",
          value: CampaignDeliverableStatus.AwaitingClientApproval,
        },
        {
          label: "In Production",
          value: CampaignDeliverableStatus.InProduction,
        },
        { label: "Approved", value: CampaignDeliverableStatus.Approved },
        {
          label: "Ad Scaffolded",
          value: CampaignDeliverableStatus.AdScaffolded,
        },
        { label: "Scheduled", value: CampaignDeliverableStatus.Scheduled },
      ]
    : [
        { label: "Brief Needed", value: CampaignDeliverableStatus.BriefNeeded },
        {
          label: "Assigned to Creative",
          value: CampaignDeliverableStatus.AssignedToCreative,
        },
        {
          label: "Awaiting Creator Approval",
          value: CampaignDeliverableStatus.AwaitingCreatorApproval,
        },
        {
          label: "Awaiting Account Manager Approval",
          value: CampaignDeliverableStatus.AwaitingAccountManagerApproval,
        },
        {
          label: "Awaiting Client Approval",
          value: CampaignDeliverableStatus.AwaitingClientApproval,
        },
        {
          label: "In Production",
          value: CampaignDeliverableStatus.InProduction,
        },
        { label: "Approved", value: CampaignDeliverableStatus.Approved },
        {
          label: "Ad Scaffolded",
          value: CampaignDeliverableStatus.AdScaffolded,
        },
        { label: "Scheduled", value: CampaignDeliverableStatus.Scheduled },
        ...(formData.dashboardView === DashboardView.calendar
          ? [{ label: "Published", value: CampaignDeliverableStatus.Published }]
          : []),
      ]

  const deliverableTypeOptions = [
    { label: "Newsletter", value: CampaignDeliverableType.NewsletterAds },
    { label: "Deep Dive", value: CampaignDeliverableType.DeepDives },
    { label: "Leads", value: CampaignDeliverableType.LeadGen },
    { label: "Podcast Ads", value: CampaignDeliverableType.Podcasts },
    {
      label: "Virtual Event Sponsorship",
      value: CampaignDeliverableType.VirtualEvents,
    },
    {
      label: "Live Event Sponsorship",
      value: CampaignDeliverableType.LiveEvents,
    },
    {
      label: "Branded Article",
      value: CampaignDeliverableType.BrandedArticles,
    },
    { label: "Social", value: CampaignDeliverableType.SocialPosts },
    { label: "Podcast Guest", value: CampaignDeliverableType.PodcastGuest },
    {
      label: "Live or Virtual Event Speaking Fee",
      value: CampaignDeliverableType.LiveOrVirtualEventSpeakingFee,
    },
    {
      label: "Custom Live Event",
      value: CampaignDeliverableType.CustomLiveEvent,
    },
    {
      label: "Custom Virtual Event",
      value: CampaignDeliverableType.CustomVirtualEvent,
    },
    {
      label: "Custom Podcast Content",
      value: CampaignDeliverableType.CustomPodcastContent,
    },
    { label: "Lead Gen Magnet", value: CampaignDeliverableType.LeadGenMagnet },
  ]

  const handlePrevious = () => {
    if (calendarView === CalendarView.weekly) {
      setCurrentDate((prev) => subWeeks(prev, 1))
    } else {
      setCurrentDate((prev) => subMonths(prev, 1))
    }
  }

  const handleNext = () => {
    if (calendarView === CalendarView.weekly) {
      setCurrentDate((prev) => addWeeks(prev, 1))
    } else {
      setCurrentDate((prev) => addMonths(prev, 1))
    }
  }

  const internalUser = [
    Role.WorkweekAdmin,
    Role.WorkweekTeam,
    Role.Creative,
  ].includes(viewer?.role)

  return (
    <div className="grid grid-cols-[1fr_300px] flex-1 overflow-y-auto">
      <div className="border-e">
        <div className="flex h-[60px]">
          <div className="flex items-center justify-between p-4">
            <div className="flex items-center">
              {formData.dashboardView === DashboardView.calendar ? (
                <>
                  <Button
                    variant="ghost"
                    onClick={handlePrevious}
                    className="p-1 w-8 h-8"
                  >
                    <img
                      {...arrowDown}
                      alt="Previous"
                      className="w-3 h-3 rotate-90"
                    />
                  </Button>
                  <Text variant="body-10-bold" className="uppercase px-1">
                    {formData.dashboardView === DashboardView.calendar
                      ? calendarView === CalendarView.weekly
                        ? `${format(
                            startOfISOWeek(currentDate),
                            "MM/dd/yyyy"
                          )}-${format(endOfISOWeek(currentDate), "MM/dd/yyyy")}`
                        : format(currentDate, "MMMM yyyy")
                      : null}
                  </Text>
                  <Button
                    variant="ghost"
                    onClick={handleNext}
                    className="p-1 w-8 h-8"
                  >
                    <img
                      {...arrowDown}
                      alt="Next"
                      className="w-3 h-3 -rotate-90"
                    />
                  </Button>
                </>
              ) : null}
            </div>
          </div>
          <Form {...form}>
            <form className="flex items-center gap-2 ms-auto pe-6">
              {formData.dashboardView === DashboardView.calendar && (
                <SelectField
                  name="calendarView"
                  control={form.control}
                  text={({ value }) => (
                    <div className="flex items-center gap-2 pr-2">
                      <CalendarIcon className="inline align-baseline" />{" "}
                      {calendarViewLables[value as CalendarView]}
                    </div>
                  )}
                  options={Object.entries(calendarViewLables).map(
                    ([value, label]) => ({
                      value,
                      label,
                    })
                  )}
                />
              )}
              {formData.dashboardView === DashboardView.table && (
                <SelectField
                  name="period"
                  control={form.control}
                  text={({ value }) => (
                    <div className="flex items-center gap-2 pr-2">
                      <CalendarIcon className="inline align-baseline" />{" "}
                      {periodLables[value as Period]}
                    </div>
                  )}
                  options={Object.entries(periodLables).map(
                    ([value, label]) => ({
                      value,
                      label,
                    })
                  )}
                />
              )}
              <FilterField
                control={form.control}
                name="statuses"
                options={statusOptions}
                text="Status"
                variant="lg"
              />
              <FilterField
                control={form.control}
                name="creatorBrandIds"
                options={creatorBrandOptions}
                text="Creator Brand"
                variant="lg"
              />
              <FilterField
                control={form.control}
                name="deliverableTypes"
                options={deliverableTypeOptions}
                text="Deliverable Type"
                variant="lg"
              />
              {internalUser && (
                <div className="flex items-center">
                  <Button
                    type="button"
                    variant={
                      formData.dashboardView === DashboardView.table
                        ? "default"
                        : "ghost"
                    }
                    onClick={() =>
                      form.setValue("dashboardView", DashboardView.table)
                    }
                    className={cn(
                      "rounded-e-none border-l-0 border",
                      formData.dashboardView === DashboardView.table &&
                        "bg-black border-black text-white"
                    )}
                  >
                    <span className="sr-only">Table</span>
                    <TableIcon className="inline align-baseline" />
                  </Button>
                  <Button
                    type="button"
                    variant={
                      formData.dashboardView === DashboardView.calendar
                        ? "default"
                        : "ghost"
                    }
                    onClick={() =>
                      form.setValue("dashboardView", DashboardView.calendar)
                    }
                    className={cn(
                      "rounded-s-none border-l-0 border",
                      formData.dashboardView === DashboardView.calendar &&
                        "bg-black border-black text-white"
                    )}
                  >
                    <span className="sr-only">Calendar</span>
                    <CalendarIcon className="inline align-baseline" />
                  </Button>
                </div>
              )}
            </form>
          </Form>
        </div>
        <div className="p-2">
          {activeFiltersCount > 0 && (
            <div className="bg-gray-50 rounded-lg p-5 py-4 flex items-center gap-6">
              <Heading
                title="Filters applied"
                count={activeFiltersCount}
                className="mb-0"
              />
              {nonDefaultValues.statuses &&
                nonDefaultValues.statuses.length > 0 && (
                  <ActiveFilters
                    label="Status"
                    values={formData.statuses
                      .map(
                        (status) =>
                          statusOptions.find(
                            (option) => option.value === status
                          )?.label ?? ""
                      )
                      .join(", ")}
                    onClear={() => form.setValue("statuses", [])}
                  />
                )}
              {nonDefaultValues.creatorBrandIds &&
                nonDefaultValues.creatorBrandIds.length > 0 && (
                  <ActiveFilters
                    label="Creator Brand"
                    values={formData.creatorBrandIds
                      .map(
                        (creatorBrandId) =>
                          creatorBrandOptions.find(
                            (option) => option.value === creatorBrandId
                          )?.label ?? ""
                      )
                      .join(", ")}
                    onClear={() => form.setValue("creatorBrandIds", [])}
                  />
                )}
              {nonDefaultValues.deliverableTypes &&
                nonDefaultValues.deliverableTypes.length > 0 && (
                  <ActiveFilters
                    label="Deliverable Type"
                    values={formData.deliverableTypes
                      .map(
                        (type) =>
                          deliverableTypeOptions.find(
                            (option) => option.value === type
                          )?.label ?? ""
                      )
                      .join(", ")}
                    onClear={() => form.setValue("deliverableTypes", [])}
                  />
                )}
            </div>
          )}
        </div>
        <div className="flex flex-1 overflow-auto">
          {result.error ? (
            <GraphqlError error={result.error} />
          ) : result.loading ? (
            <LoadingIndicatorCentered />
          ) : result.data ? (
            formData.dashboardView === DashboardView.table ? (
              <DeliverableTable campaignDeliverables={records} form={form} />
            ) : (
              <DeliverableCalendarView
                campaignDeliverables={records}
                viewType={calendarView}
                currentDate={currentDate}
              />
            )
          ) : (
            <Invariant />
          )}
        </div>
      </div>
      <div className="">
        <ActionItems />
      </div>
    </div>
  )
}

gql(/* GraphQL */ `
  fragment CampaignDeliverableRow on CampaignDeliverable {
    id
    deliverableName
    deliverableType
    campaign {
      id
      campaignName
      company {
        id
        ...CompanyLogo
      }
    }
    status
    publishDateCanonical
    actionDate
    ...CampaignDeliverableDueDates

    creatorBrand {
      id
      name
    }
  }
`)

const DeliverableTable: React.FC<{
  campaignDeliverables: Array<CampaignDeliverableRowFragment>
  form: ReturnType<typeof useForm<z.infer<typeof formSchema>>>
}> = ({ campaignDeliverables, form }) => {
  const navigate = useNavigate()

  const handleSort = (field: string) => {
    const currentField = form.getValues("sortField")
    const currentDirection = form.getValues("sortDirection")

    if (field === currentField) {
      const newDirection = currentDirection === "asc" ? "desc" : "asc"
      form.setValue("sortDirection", newDirection)
    } else {
      form.setValue("sortField", field as "nextDueDate" | "publishDate")
      form.setValue("sortDirection", "asc")
    }
  }

  const sortedDeliverables = React.useMemo(() => {
    const field = form.watch("sortField")
    const direction = form.watch("sortDirection")

    const sorted = sortBy(campaignDeliverables, (deliverable) => {
      if (field === "nextDueDate") {
        return deliverable.nextDueDate || "9999-99-99"
      }
      return deliverable.publishDateCanonical || "9999-99-99"
    })

    return direction === "asc" ? sorted : sorted.reverse()
  }, [campaignDeliverables, form])

  const currentWeekStart = startOfISOWeek(new Date())
  const currentWeekKey = formatISO(currentWeekStart, { representation: "date" })

  const groupedByActionDateWeek = sortedDeliverables.reduce(
    (acc, deliverable) => {
      let week
      if (deliverable.actionDate) {
        const actionDate = new Date(deliverable.actionDate)
        if (isBefore(actionDate, currentWeekStart)) {
          // If the action date is in the past, group it under the current week
          week = currentWeekKey
        } else {
          // For future dates, keep them in their respective weeks
          week = formatISO(startOfISOWeek(actionDate), {
            representation: "date",
          })
        }
      } else {
        week = "unknown"
      }

      acc[week] = [...(acc[week] ?? []), deliverable]
      return acc
    },
    {} as Record<string, CampaignDeliverableRowFragment[]>
  )

  if (Object.keys(groupedByActionDateWeek).length === 0) {
    return (
      <Text
        as="div"
        variant="subtext-10-bold"
        className="bg-gray-f9 py-3 px-[40px] flex flex-1 w-full border-t border-b"
      >
        None
      </Text>
    )
  }

  return (
    <div className="flex flex-col p-8 flex-1">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Deliverable Name</TableHead>
            <TableHead>Campaign Name</TableHead>
            <TableHead>Status</TableHead>
            <SortableTableHead
              label="Next Due Date"
              sortField="nextDueDate"
              currentSortField={form.watch("sortField")}
              currentSortDirection={form.watch("sortDirection")}
              onSort={handleSort}
            />
            <SortableTableHead
              label="Publish Date"
              sortField="publishDate"
              currentSortField={form.watch("sortField")}
              currentSortDirection={form.watch("sortDirection")}
              onSort={handleSort}
            />
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        {sortedDeliverables.map((deliverable) => (
          <TableRow
            onClick={() => {
              navigate(
                campaignDeliverablePath({
                  campaignId: deliverable.campaign.id,
                  deliverableId: deliverable.id,
                })
              )
            }}
          >
            <TableCell className="max-w-[200px]">
              <div className="flex items-center gap-2 w-full pointer-events-none">
                <CompanyLogo company={deliverable.campaign.company} size="24" />
                <div className="truncate">{deliverable.deliverableName}</div>
              </div>
            </TableCell>
            <TableCell className="max-w-[200px]">
              <div className="truncate text-ellipsis pointer-events-none">
                {deliverable.campaign.campaignName}
              </div>
            </TableCell>
            <TableCell className="py-3 max-w-[100px]">
              <div className="border border-gray-200 rounded px-2 py-1 inline-block pointer-events-none">
                <Text variant="body-14">
                  <CampaignDeliverableStatusLabel {...deliverable} />
                </Text>
              </div>
            </TableCell>
            <TableCell className="min-w-[120px] max-w-[100px]">
              {deliverable.nextDueDate
                ? formatDate(deliverable.nextDueDate)
                : "–"}
            </TableCell>
            <TableCell className="min-w-[120px] max-w-[100px]">
              {deliverable.publishDateCanonical
                ? formatDate(deliverable.publishDateCanonical)
                : "–"}
            </TableCell>
            <TableCell className="max-w-[60px]">
              <Link
                to={campaignDeliverablePath({
                  campaignId: deliverable.campaign.id,
                  deliverableId: deliverable.id,
                })}
                className="flex items-center justify-end"
              >
                <span className="sr-only">View</span>
                <img
                  {...arrowRight}
                  alt=""
                  width={16}
                  height={16}
                  className="w-4 h-4"
                />
              </Link>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </div>
  )
}

interface SortableTableHeadProps {
  label: string
  sortField: string
  currentSortField?: string
  currentSortDirection?: "asc" | "desc"
  onSort: (field: string) => void
  className?: string
}

export const SortableTableHead: React.FC<SortableTableHeadProps> = ({
  label,
  sortField,
  currentSortField,
  currentSortDirection,
  onSort,
}) => {
  const isActive = currentSortField === sortField

  return (
    <TableHead
      onClick={() => onSort(sortField)}
      className="cursor-pointer hover:bg-gray-50 select-none"
    >
      <div
        className={cn(
          "flex items-center gap-1",
          isActive && "text-black font-semibold"
        )}
      >
        {label}
        {isActive && (
          <img
            src={
              currentSortDirection === "desc" ? chevronUp.src : chevronDown.src
            }
            alt=""
            className="w-3 h-3"
          />
        )}
      </div>
    </TableHead>
  )
}

interface DeliverableCalendarViewProps {
  campaignDeliverables: Array<CampaignDeliverableRowFragment>
  viewType: "weekly" | "monthly"
  currentDate: Date
}

const DeliverableCalendarView: React.FC<DeliverableCalendarViewProps> = ({
  campaignDeliverables,
  viewType,
  currentDate,
}) => {
  const [expandedDays, setExpandedDays] = React.useState<
    Record<string, boolean>
  >({})

  const days = React.useMemo(() => {
    if (viewType === "weekly") {
      const start = startOfISOWeek(currentDate)
      const end = endOfISOWeek(currentDate)
      return eachDayOfInterval({ start, end })
    } else {
      const start = startOfMonth(currentDate)
      const end = endOfMonth(currentDate)
      return eachDayOfInterval({ start, end })
    }
  }, [currentDate, viewType])

  const deliverablesByDay = React.useMemo(() => {
    const grouped = days.reduce(
      (acc, day) => {
        const dayStr = formatISO(day, { representation: "date" })
        acc[dayStr] = campaignDeliverables.filter(
          (d) => d.publishDateCanonical === dayStr
        )
        return acc
      },
      {} as Record<string, CampaignDeliverableRowFragment[]>
    )

    const initialExpanded = Object.entries(grouped).reduce(
      (acc, [day, deliverables]) => {
        acc[day] = deliverables.length > 0
        return acc
      },
      {} as Record<string, boolean>
    )

    setExpandedDays((prev) => ({
      ...initialExpanded,
      ...prev,
    }))

    return grouped
  }, [days, campaignDeliverables])

  const toggleDay = (dayStr: string) => {
    setExpandedDays((prev) => ({
      ...prev,
      [dayStr]: !prev[dayStr],
    }))
  }

  return (
    <div className="flex flex-col h-full flex-1">
      <div className="flex-1 overflow-auto">
        {days.map((day, index) => {
          const dayStr = formatISO(day, { representation: "date" })
          const deliverables = deliverablesByDay[dayStr] || []
          const isExpanded = expandedDays[dayStr]

          return (
            <div key={day.toISOString()}>
              <div
                className={cn(
                  "flex items-center gap-2 bg-gray-f9 py-3 px-[40px] flex-1 border-t cursor-pointer hover:bg-gray-100",
                  (index === days.length - 1 || isExpanded) && "border-b"
                )}
                onClick={() => toggleDay(dayStr)}
              >
                <Text as="div" variant="subtext-10-bold" className="">
                  {format(day, "EEEE, MMMM d, yyyy")}
                </Text>
                <span className="border border-gray-400 rounded-md h-4 w-5 flex items-center justify-center">
                  <Text variant="body-10">{deliverables.length}</Text>
                </span>
                {isExpanded ? (
                  <img src={arrowDown.src} alt="" className="w-3 h-3" />
                ) : (
                  <img
                    src={arrowDown.src}
                    alt=""
                    className="w-3 h-3 -rotate-90"
                  />
                )}
              </div>

              {isExpanded && (
                <div className="p-6 gap-4 grid">
                  {deliverables.map((deliverable) => (
                    <DeliverableCalendarViewRow
                      key={deliverable.id}
                      deliverable={deliverable}
                    />
                  ))}
                  {deliverables.length === 0 && (
                    <div className="p-4 py-0">
                      <Text variant="body-14" className="text-gray-500 italic">
                        No deliverables
                      </Text>
                    </div>
                  )}
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const DeliverableCalendarViewRow: React.FC<{
  deliverable: CampaignDeliverableRowFragment
}> = ({ deliverable }) => {
  return (
    <Link
      to={campaignDeliverablePath({
        campaignId: deliverable.campaign.id,
        deliverableId: deliverable.id,
      })}
      className="flex flex-1"
    >
      <div className="grid grid-cols-[24px_minmax(100px,1fr)_minmax(150px,1fr)_minmax(150px,1fr)_minmax(200px,1fr)] gap-4 content-center items-center px-6 border border-gray-300 rounded-md w-full">
        <div className="py-6">
          <CompanyLogo company={deliverable.campaign.company} size="24" />
        </div>
        <div className="truncate text-ellipsis">
          <Text variant="body-14-medium">
            {deliverable.campaign.company.name}
          </Text>
        </div>
        <div className="flex gap-4 items-center">
          <div className="truncate text-ellipsis max-w-[280px]">
            <Text variant="body-14">{deliverable.deliverableName}</Text>
          </div>
        </div>
        <div className="flex gap-4 items-center">
          <Text variant="body-14">
            <DeliverableType deliverableType={deliverable.deliverableType} />
          </Text>
        </div>
        <div className="text-right flex items-center justify-end gap-4">
          <div className="border border-gray-d0 rounded px-2 py-1 whitespace-nowrap">
            <Text variant="body-14">
              <DeliverableStatus status={deliverable.status} />
            </Text>
          </div>
          <Button variant="ghost" size="icon" className="ms-1">
            <span className="sr-only">View</span>
            <img {...arrowRight} alt="" width={16} height={16} />
          </Button>
        </div>
      </div>
    </Link>
  )
}

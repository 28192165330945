import {
  CampaignMetricsDeliverableFragment,
  Role,
} from "~/__generated__/graphql"
import { useViewer } from "~/auth/viewer-context"
import { formatDate } from "~/common/date-formatting"
import * as paths from "~/common/paths"
import arrowDown from "~/images/icons/arrow-down"
import { Anchor } from "~/ui/link"
import { TableCell } from "~/ui/table"
import Text from "~/ui/typography"

interface DeliverableBasicInfoProps {
  deliverable: CampaignMetricsDeliverableFragment
  isOpen: boolean
  toggleOpen: () => void
  tableProps: { variant: "top" | "default" }
}

export const DeliverableBasicInfo = ({
  deliverable,
  isOpen,
  toggleOpen,
  tableProps,
}: DeliverableBasicInfoProps) => {
  const { viewer } = useViewer()
  const isWorkweekAdmin = viewer?.role === Role.WorkweekAdmin

  return (
    <>
      <TableCell
        {...tableProps}
        className="p-0 first:pl-4"
        onClick={toggleOpen}
      >
        {!isOpen && (
          <img {...arrowDown} alt="" className="w-3 h-3 -rotate-90" />
        )}
        {isOpen && <img {...arrowDown} alt="" className="w-3 h-3" />}
      </TableCell>
      <TableCell {...tableProps} className="py-4">
        {deliverable.publishDateCanonical
          ? formatDate(deliverable.publishDateCanonical, "MMMM d, yyyy")
          : "–"}
      </TableCell>
      <TableCell {...tableProps} className="py-4">
        <Text variant="body-14-medium">
          <Anchor
            target="_blank"
            className="no-underline hover:underline"
            href={paths.campaignDeliverablePath({
              campaignId: deliverable.campaign.id,
              deliverableId: deliverable.id,
            })}
          >
            {deliverable.deliverableName}
          </Anchor>
        </Text>
      </TableCell>
      <TableCell {...tableProps} className="py-4">
        <Text variant="body-14-medium" title={deliverable.productBrief?.name}>
          <Anchor
            target="_blank"
            className="no-underline hover:underline"
            href={paths.companyProductsDetailPath({
              slug: deliverable.productBrief?.company?.slug ?? "",
              productId: deliverable.productBrief?.id ?? "",
            })}
          >
            {deliverable.productBrief?.name}
          </Anchor>
        </Text>
      </TableCell>
      <TableCell {...tableProps} className="py-4">
        <Text variant="body-14-medium" title={deliverable.creatorBrand?.name}>
          {isWorkweekAdmin ? (
            <Anchor
              target="_blank"
              className="no-underline hover:underline"
              href={paths.creatorBrandsPath({
                creatorBrandId: deliverable.creatorBrand?.id ?? "",
              })}
            >
              {deliverable.creatorBrand?.name}
            </Anchor>
          ) : (
            deliverable.creatorBrand?.name
          )}
        </Text>
      </TableCell>
    </>
  )
}
